<template>
  <div class="profile__cards-info-content">
    <div class="profile-title profile__cards-info-title">История операций</div>  
    <div class="profile__info-history">
      <div class="profile__cards-info-subtitle" v-if="false">Операции {{ items.length>0?'1-'+items.length:'0' }} ({{ date }})</div>
      <div class="profile__info-history-content">
        <div class="profile__info-history-content-wrapper">
          <div class="profile__info-history-row profile__info-history-row_heading">
            <div class="profile__info-history-date">Дата</div>
            <div class="profile__info-history-description">Операция</div>
            <div class="profile__info-history-sum">Сумма</div>
          </div>
          <div 
            class="profile__info-history-row"
            v-for="(item,index) in items"
            :key="index"
          >
            <div class="profile__info-history-date">
              <div class="profile__info-history-row-title">Дата</div>
              {{item.date}}
            </div>
            <div class="profile__info-history-description">
              <div class="profile__info-history-row-title">Операция</div>
              {{item.operation}}
            </div>
            <div class="profile__info-history-sum">
              <div class="profile__info-history-row-title">Сумма</div>
              {{item.summDiscounted}}
            </div>
          </div>
        </div>

        <div class="profile__cards-info-subtitle">Сумма всех операций указана в тнг.</div>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "@/services/api.service";
import {mapGetters} from "vuex";

export default {
  props:['number'],
  components: {
  },
  data() {
    return {
      date: 'На 23.04.2021 18:28',
      count: '1-25',
      operationInfo: {
        date: '07.10.2020',
        time: '13:07',
        description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod ipsa laboriosam eos reprehenderit eligendi laborum eum fuga, iure quos odio. Id laboriosam ducimus corrupti dolorum ut consequatur odio repellat excepturi.',
        sum: '1315.00 руб.'
      },

      items:{}
    }
  },
  computed: {
    ...mapGetters({
      cards: "cards/cards"
    }),
    card() {
      for (let card of this.cards) {
        if (card.Number == this.number)
          return card;
      }
      return null;
    },
  },
  mounted() {
    ApiService.chequeByCardId(this.card.Id).then((resp)=>{
      this.items=resp.data;
    });
  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables';

  .profile__info-history {
    &-row {
      display: flex;
      border: 1px solid rgba($color-dark, 0.1);
      padding: 16px;
      background-color: $color-light;

      @include breakpoint(600) {
        flex-wrap: wrap;
      }

      &:not(:last-child) {
        border-bottom: none;
      }

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-top: 22px;

        @include breakpoint(600) {
          display: none;
        }
      }

      &:last-of-type {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:nth-child(2n + 2) {
        background-color: $color-white;
      }

      &-title,
      &_heading {
        color: rgba($color-dark, 0.5);
        font-weight: 500;
      }

      &-title {
        display: none;

        @include breakpoint(600) {
          display: block;
          margin-bottom: 4px;
        }
      }
    }

    &-content {
      max-width: 100%;
    }

    &-date {
      width: 120px;

      @include breakpoint(600) {
        width: calc(50% - 16px);
      }
    }

    &-sum {
      max-width: 60px;
      text-align: right;

      @include breakpoint(600) {
        order: 2;
        width: calc(50% - 16px);
        text-align: left;
        margin-left: auto;
        max-width: none;
      }
    }

    &-description {
      max-width: calc(100% - 210px);
      width: 70%;
      padding: 0 16px;

      @include breakpoint(600) {
        order: 3;
        width: 100%;
        max-width: none;
        padding: 0;
        margin-top: 12px;
      }
    }
  }
</style>
